<script setup lang="ts">
import RubyLogo from "@/components/ui/RubyLogo.vue"
</script>
<template>
  <div class="z-[9999] text-stone-900 fixed left-0 bottom-0 bg-transparent cursor-pointer">
    <div class="p-1 pt-1.5">
      <ruby-logo />
    </div>
  </div>
</template>
